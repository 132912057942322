import { gql } from '@apollo/client/core';
import { quoteInsightsFragment } from './insight';
import { quoteDiscountFragment } from './quoteDiscounts';
import { quoteFeeFragment } from './quoteFees';
import { quoteSectionFragment } from './quoteSection';
import { quoteTagFragment } from './quoteTags';
import { termFragment } from './terms';

export const quoteSettingFragment = gql`
  ${termFragment}
  fragment QuoteSettingFragment on quote_settings {
    id
    quote_id
    header
    page_footer
    footer
    term_id
    term {
      ...TermFragment
    }
    export_price_display_mode
    export_display_mode
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;

export const quoteDataFragment = gql`
  ${quoteDiscountFragment}
  ${quoteSettingFragment}
  ${quoteTagFragment}
  fragment QuoteDataFragment on quotes {
    client_id
    assignee_id
    attachments
    customer_id
    customer {
      id
      name
      reference
    }
    date
    description
    id
    name
    reference
    status
    version
    cost
    kpis
    client_company_id
    _created_at
    _created_by
    _updated_at
    _updated_by
    quote_settings {
      ...QuoteSettingFragment
    }
    quote_discounts(order_by: { order: asc }, where: { _deleted_at: { _is_null: true } }) {
      ...QuoteDiscountFragment
    }
    quote_tags {
      ...QuoteTagFragment
    }
  }
`;

export const quoteFragment = gql`
  ${quoteDataFragment}
  ${quoteSectionFragment}
  ${quoteInsightsFragment}
  ${quoteFeeFragment}
  fragment QuoteFragment on quotes {
    ...QuoteDataFragment
    customer {
      id
      name
      reference
    }
    insights(order_by: { _created_at: desc }, where: { status: { _is_null: true } }){
      ...QuoteInsightsFragment
    }
    quote_sections(order_by: { order: asc }, where: { _deleted_at: { _is_null: true } }) {
      ...QuoteSectionFragment
    }
    quote_fees(order_by: { order: asc }, where: { _deleted_at: { _is_null: true } }) {
      ...QuoteFeeFragment
    }
  }
`;
