import { aiAgentRunFragment } from '#fragments/ai_agent_runs.js';
import { gql } from '@apollo/client/core';

export const AI_AGENT_RUNS_QUERY = gql`
  ${aiAgentRunFragment}
  query GetAiAgentRun {
    ai_agent_runs {
      ...AiAgentRunFragment
    }
  }
`;

export const AI_AGENT_RUN_QUERY_BY_ID = gql`
  ${aiAgentRunFragment}
  query GetAiAgentRun($id: Int!) {
    ai_agent_runs_by_pk(id: $id) {
      ...AiAgentRunFragment
    }
  }
`;

export const AI_AGENT_RUN_INSERT = gql`
  ${aiAgentRunFragment}
  mutation InsertAiAgentRun($object: ai_agent_runs_insert_input!) {
    insert_ai_agent_runs_one(object: $object) {
      ...AiAgentRunFragment
    }
  }
`;

export const AI_AGENT_RUN_UPDATE = gql`
  ${aiAgentRunFragment}
  mutation UpdateAiAgentRun($object: ai_agent_runs_set_input!, $id: Int!) {
    update_ai_agent_runs_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...AiAgentRunFragment
    }
  }
`;

export const AI_AGENT_RUN_SUBSCRIPTION = gql`
  ${aiAgentRunFragment}
  subscription SubscribeToAiAgentRun($ai_agent_run_id: Int!) {
    ai_agent_runs_by_pk(id: $ai_agent_run_id) {
      ...AiAgentRunFragment
    }
  }
`;
