import { gql } from '@apollo/client/core';

export const subcontractingVersionFragment = gql`
  fragment SubcontractingVersionFragment on subcontracting_versions {
    id
    subcontracting_id
    version
    is_latest
    name
    value
    attachments
    margin
    _created_at
    _updated_at
    _created_by
    _updated_by
  }
`;

export const subcontractingDataFragment = gql`
  fragment SubcontractingDataFragment on subcontractings {
    id
    client_id
    _created_at
    _updated_at
    _created_by
    _updated_by
  }
`;

export const subcontractingFragment = gql`
  ${subcontractingDataFragment}
  ${subcontractingVersionFragment}
  fragment SubcontractingFragment on subcontractings {
    ...SubcontractingDataFragment
    subcontracting_version(where: { is_latest: { _eq: true } }) {
      ...SubcontractingVersionFragment
    }
  }
`;

export const quoteItemSubcontractingFragment = gql`
  ${subcontractingFragment}
  fragment QuoteItemSubcontractingFragment on quote_item_subcontractings {
    id
    quote_section_id
    subcontracting_id
    description
    order

    _created_at
    _updated_at
    _created_by
    _updated_by
    _deleted_at
    subcontracting {
      ...SubcontractingFragment
    }
  }
`;

export const moduleSubcontractingLinkFragment = gql`
  ${subcontractingFragment}
  fragment ModuleSubcontractingLinkFragment on module_subcontracting_link {
    id
    module_id
    subcontracting_id
    order
    _created_at
    _updated_at
    _created_by
    _updated_by
    _deleted_at
    subcontracting {
      ...SubcontractingFragment
    }
  }
`;
