import { quoteItemDatabaseObjectFragment, quoteItemModuleFragment, quoteItemTaxFragment } from '#fragments/quoteSection';
import { quoteItemRuledPriceFragment } from '#fragments/ruledPrices';
import { gql } from '@apollo/client/core';

export const QUOTE_ITEM_MODULE = gql`
  ${quoteItemModuleFragment}
  query quote_item_modules {
    quote_item_modules(where: { module: { _deleted_at: { _is_null: true } }, _deleted_at: { _is_null: true } }) {
      ...QuoteItemModuleFragment
    }
  }
`;

export const QUOTE_ITEM_MODULE_CREATE = gql`
  ${quoteItemModuleFragment}
  mutation CreateQuoteItemModule($object: quote_item_module_insert_input!) {
    insert_quote_item_module_one(object: $object) {
      ...QuoteItemModuleFragment
    }
  }
`;

export const QUOTE_ITEM_MODULE_UPDATE = gql`
 ${quoteItemModuleFragment}
  mutation UpdateQuoteItemModule($object: quote_item_module_set_input!, $id: Int!) {
    update_quote_item_module_by_pk(pk_columns:{ id:$id }, _set: $object) {
      ...QuoteItemModuleFragment
    }
  }
`;

export const QUOTE_ITEM_MODULE_BULK_UPDATE = gql`
  mutation UpdateQuoteItemModules($updates: [quote_item_module_updates!]!) {
    update_quote_item_module_many(updates: $updates) {
      affected_rows
    }
  }
`;

export const QUOTE_ITEM_MODULE_DELETE = gql`
  mutation DeleteQuoteItemModule($id: Int!) {
    update_quote_item_module_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
    }
  }
`;

export const QUOTE_ITEM_OBJECTS = gql`
  ${quoteItemDatabaseObjectFragment}
  query quote_item_objects {
    quote_item_objects(where: { database_object: { _deleted_at: { _is_null: true } } }) {
      ...QuoteItemDatabaseObjectFragment
    }
  }
`;

export const QUOTE_ITEM_OBJECT_UPDATE = gql`
  ${quoteItemDatabaseObjectFragment}
  mutation UpdateQuoteItemObject($object: quote_item_database_object_set_input!, $id: Int!) {
    update_quote_item_database_object_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteItemDatabaseObjectFragment
    }
  }
`;

export const QUOTE_ITEM_OBJECT_BULK_UPDATE = gql`
  mutation UpdateQuoteItemObjects($updates: [quote_item_database_object_updates!]!) {
    update_quote_item_database_object_many(updates: $updates) {
      affected_rows
    }
  }
`;

export const QUOTE_ITEM_OBJECT_CREATE = gql`
  ${quoteItemDatabaseObjectFragment}
  mutation CreateQuoteItemObject($object: quote_item_database_object_insert_input!) {
    insert_quote_item_database_object_one(object: $object) {
      ...QuoteItemDatabaseObjectFragment
    }
  }
`;

export const QUOTE_ITEM_OBJECT_DELETE = gql`
  mutation DeleteQuoteItemDetabaseObject($id: Int!) {
    update_quote_item_database_object_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
    }
  }
`;

export const QUOTE_ITEM_TAX_CREATE = gql`
${quoteItemTaxFragment}
  mutation CreateQuoteItemTax($object: quote_item_taxes_insert_input!) {
    insert_quote_item_taxes_one(object: $object) {
      ...QuoteItemTaxFragment
    }
  }
`;

export const QUOTE_ITEM_TAX_UPDATE = gql`
  ${quoteItemTaxFragment}
  mutation UpdateQuoteItemTax($object: quote_item_taxes_set_input!, $id: bigint!) {
    update_quote_item_taxes_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteItemTaxFragment
    }
  }
`;

export const QUOTE_ITEM_TAX_DELETE = gql`
  mutation DeleteQuoteItemTax($id: bigint!) {
    update_quote_item_taxes_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
    }
  }
`;

export const QUOTE_ITEM_RULED_PRICE_CREATE = gql`
${quoteItemRuledPriceFragment}
  mutation CreateQuoteItemRuledPrice($object: quote_item_ruled_prices_insert_input!) {
    insert_quote_item_ruled_prices_one(object: $object) {
      ...QuoteItemRuledPriceFragment
    }
  }
`;

export const QUOTE_ITEM_RULED_PRICE_UPDATE = gql`
  ${quoteItemRuledPriceFragment}
  mutation UpdateQuoteItemRuledPrice($object: quote_item_ruled_prices_set_input!, $id: bigint!) {
    update_quote_item_ruled_prices_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteItemRuledPriceFragment
    }
  }
`;

export const QUOTE_ITEM_RULED_PRICE_BULK_UPDATE = gql`
  mutation UpdateQuoteItemRuledPrices($updates: [quote_item_ruled_prices_updates!]!) {
    update_quote_item_ruled_prices_many(updates: $updates) {
      affected_rows
    }
  }
`;

export const QUOTE_ITEM_RULED_PRICE_DELETE = gql`
  mutation DeleteQuoteItemRuledPrice($id: bigint!) {
    update_quote_item_ruled_prices_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
    }
  }
`;
