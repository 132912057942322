import { quoteDataFragment, quoteFragment } from '#fragments/quotes';
import { quoteTagFragment } from '#fragments/quoteTags';
import { gql } from '@apollo/client/core';

export const QUOTES_QUERY_LIST_WITH_NAME = gql`
  ${quoteTagFragment}
  query GetQuotesWithNameSearch($limit: Int, $offset: Int, $orderBy: [quotes_order_by!], $where: quotes_bool_exp, $nameSearch: String) {
    search_quotes_by_name(
      args: { name_search: $nameSearch},
      limit: $limit,
      offset: $offset,
      order_by: $orderBy,
      where: $where,
    ) {
      _created_at
      _created_by
      _updated_at
      _updated_by
      assignee_id
      attachments
      customer_id
      date
      description
      estimated_margin
      id
      name
      reference
      status
      version
      cost
      quote_tags {
        ...QuoteTagFragment
      }
      customer {
        id
        name
        reference
      }
    }
  }
`;

export const QUOTES_QUERY_LIST = gql`
  ${quoteTagFragment}
  query GetQuotes($limit: Int, $offset: Int, $orderBy: [quotes_order_by!], $where: quotes_bool_exp, $nameSearch: String) {
    quotes(
      limit: $limit,
      offset: $offset,
      order_by: $orderBy,
      where: $where,
    ) {
      _created_at
      _created_by
      _updated_at
      _updated_by
      assignee_id
      attachments
      customer_id
      date
      description
      estimated_margin
      id
      name
      reference
      status
      version
      cost
      quote_tags {
        ...QuoteTagFragment
      }
      customer {
        id
        name
        reference
      }
    }
  }
`;

export const ARCHIVED_QUOTES_QUERY_LIST = gql`
  ${quoteTagFragment}
  query GetArchivedQuotes {
    quotes(where: { status: { _eq: "archived" } }) {
      _created_at
      _created_by
      _updated_at
      _updated_by
      assignee_id
      attachments
      customer_id
      date
      description
      estimated_margin
      id
      name
      reference
      status
      version
      quote_tags {
        ...QuoteTagFragment
      }
      customer {
        id
        name
        reference
      }
    }
  }
`;

export const QUOTE_QUERY = gql`
  ${quoteDataFragment}
  query QuoteQuery($id: Int!) {
    quotes_by_pk(id: $id) {
      ...QuoteDataFragment
    }
  }
`;

export const QUOTE_UPDATE = gql`
  ${quoteDataFragment}
  mutation UpdateQuote($object: quotes_set_input!, $id: Int!) {
    update_quotes_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteDataFragment
    }
  }
`;

export const QUOTE_INSERT = gql`
  ${quoteFragment}
  mutation InsertQuote($object: quotes_insert_input!) {
    insert_quotes_one(object: $object) {
      ...QuoteFragment
    }
  }
`;

export const QUOTE_DELETE = gql`
  mutation DeleteQuote($id: Int!) {
    update_quotes_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
    }
  }
`;
