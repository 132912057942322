import { clientInsightsFragment, databaseInsightsFragment, insightFragment, moduleInsightsFragment, quoteInsightsFragment } from '#fragments/insight';
import { gql } from '@apollo/client/core';

export const CLIENT_INSIGHT_QUERY_LIST = gql`
  ${clientInsightsFragment}
  query InsightsQuery($client_id: Int!) {
    client_insights(where: {client_id: { _eq: $client_id },_or: [ { status: { _is_null: true } },{ status: { _eq: "applied" } }]}, order_by: { _created_at: desc }) {
      ...InsightsFragment
    }
  }
`;

export const DATABASE_INSIGHT_QUERY_LIST = gql`
  ${databaseInsightsFragment}
  query DatabaseInsightsQuery($databaseId: Int!) {
    database_insights(where: {database_id: {_eq: $databaseId}, status: {_is_null: true}}, order_by: { _created_at: desc }) {
      ...DatabaseInsightsFragment
    }
  }
`;

export const MODULE_INSIGHT_QUERY_LIST = gql`
  ${moduleInsightsFragment}
  query ModuleInsightsQuery($moduleId: Int!) {
    module_insights(where: {module_id: {_eq: $moduleId}, status: {_is_null: true}}, order_by: { _created_at: desc }) {
      ...ModuleInsightsFragment
    }
  }
`;

export const QUOTE_INSIGHT_QUERY_LIST = gql`
  ${quoteInsightsFragment}
  query QuoteInsightsQuery($quoteId: Int!) {
    quote_insights(where: {quote_id: {_eq: $quoteId}, status: {_is_null: true}}, order_by: { _created_at: desc }) {
      ...QuoteInsightsFragment
      job {
        ai_agent_run_id: result(path: "[0].ai_agent_run_id")
      }
    }
  }
`;

export const INSIGHT_QUERY_BY_ID = gql`
  ${insightFragment}
  query InsightByIdQuery($id: bigint!) {
    insights_by_pk(id: $id) {
      ...InsightFragment
    }
  }
`;

export const INSIGHT_UPDATE = gql`
  mutation UpdateInsight($object: insights_set_input!, $id: bigint!) {
    update_insights_by_pk(pk_columns: {id: $id}, _set: $object) {
      id
    }
  }
`;

export const INSIGHT_INSERT = gql`
  ${insightFragment}
  mutation InsertInsight($object: insights_insert_input!) {
    insert_insights_one(object: $object) {
      ...InsightFragment
    }
  }
`;

export const DATABASE_INSIGHT_LINK_INSERT = gql`
  mutation InsertDatabaseInsightLink($object: database_insight_links_insert_input!) {
    insert_database_insight_links_one(object: $object) {
      id
    }
  }
`;

export const MODULE_INSIGHT_LINK_INSERT = gql`
  mutation InsertModuleInsightLink($object: module_insight_links_insert_input!) {
    insert_module_insight_links_one(object: $object) {
      id
    }
  }
`;

export const QUOTE_INSIGHT_LINK_INSERT = gql`
  mutation InsertQuoteInsightLink($object: quote_insight_links_insert_input!) {
    insert_quote_insight_links_one(object: $object) {
      id
    }
  }
`;

export const CLIENT_INSIGHT_LINK_INSERT = gql`
  mutation InsertClientInsightLink($object: client_insight_links_insert_input!) {
    insert_client_insight_links_one(object: $object) {
      id
    }
  }
`;
