import { notificationFragment } from '#fragments/notifications';
import { gql } from '@apollo/client/core';

// Standard queries
export const NOTIFICATIONS_QUERY_LIST = gql`
  ${notificationFragment}
  query NotificationsQuery($where: notifications_bool_exp, $limit: Int, $offset: Int) {
    notifications(
      where: $where,
      order_by: { _created_at: desc },
      limit: $limit, 
      offset: $offset
    ) {
      ...NotificationFragment
    }
  }
`;

export const UNREAD_NOTIFICATIONS_COUNT_QUERY = gql`
  query UnreadNotificationsCount {
    notifications_aggregate(where: {read_at: {_is_null: true}}) {
      aggregate {
        count
      }
    }
  }
`;

export const UNREAD_NOTIFICATIONS_COUNT_SUBSCRIPTION = gql`
  subscription UnreadNotificationsCountSubscription {
    notifications_aggregate(where: {read_at: {_is_null: true}}) {
      aggregate {
        count
      }
    }
  }
`;

export const NOTIFICATION_QUERY = gql`
  ${notificationFragment}
  query NotificationByPk($id: bigint!) {
    notifications_by_pk(id: $id) {
      ...NotificationFragment
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  ${notificationFragment}
  mutation CreateNotification($object: notifications_insert_input!) {
    insert_notifications_one(object: $object) {
      ...NotificationFragment
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  ${notificationFragment}
  mutation UpdateNotification($id: bigint!, $object: notifications_set_input!) {
    update_notifications_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...NotificationFragment
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: bigint!) {
    delete_notifications_by_pk(id: $id) {
      id
    }
  }
`;

// Specific query 1: Bulk create notifications for several users (admin client)
export const BULK_CREATE_NOTIFICATIONS = gql`
  ${notificationFragment}
  mutation BulkCreateNotifications($objects: [notifications_insert_input!]!) {
    insert_notifications(objects: $objects) {
      returning {
        ...NotificationFragment
      }
      affected_rows
    }
  }
`;

// Specific query 2: Mark all unread notifications as read for a user
export const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  ${notificationFragment}
  mutation MarkAllNotificationsAsRead {
    update_notifications(
      where: { 
        read_at: { _is_null: true }
      },
      _set: { read_at: "now()" }
    ) {
      returning {
        ...NotificationFragment
      }
      affected_rows
    }
  }
`;
