import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import type { UserModule } from '~/types';

export const install: UserModule = () => {
  if (import.meta.env.MODE === 'development') {
    return;
  }

  const sessionReplayInit = sessionReplayPlugin({
    sampleRate: 1.0,

  });
  amplitude.add(sessionReplayInit);
  amplitude.init(
    String(import.meta.env.VITE_AMPLITUDE_KEY),
    undefined,
    {
      serverZone: 'EU',
      autocapture: {
        elementInteractions: true,
      },
    },
  );
};
