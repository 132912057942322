import { taxWithLatestVersionFragment } from '#fragments/taxes';
import { gql } from '@apollo/client/core';

export const quoteFeeDataFragment = gql`
  fragment QuoteFeeDataFragment on quote_fees {
    id
    quote_id
    name
    order
    type
    value
    tax_id
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;

export const quoteFeeFragment = gql`
  ${quoteFeeDataFragment}
  ${taxWithLatestVersionFragment}
  fragment QuoteFeeFragment on quote_fees {
    ...QuoteFeeDataFragment
    tax {
      ...TaxWithLatestVersionFragment
    }
  }
`;
