import type { RequestHandler } from '@apollo/client/core';
import type { JWTPayload } from 'jose';
import { ApolloClient, concat, HttpLink, InMemoryCache, split } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { getIdTokenResult } from 'firebase/auth';
import { SignJWT } from 'jose';
import { fb_auth } from '~/services/firebase';

export async function getToken(): Promise<string | undefined> {
  const user = fb_auth.currentUser;
  const token: string | undefined = await user?.getIdToken();
  return token;
}

async function getAuthToken(): Promise<string> {
  const firebaseToken = await getToken();
  let token = firebaseToken;

  if (import.meta.env.DEV) {
    const userResult = await getIdTokenResult(fb_auth.currentUser!);
    try {
      token = await new SignJWT(userResult.claims as JWTPayload)
        .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
        .sign(new TextEncoder().encode('superlongjwtsecrettosignmytokenlocally'));
    }
    catch (e) {
      console.error('Error signing token', e);
    }
  }

  return token || '';
}

const httpLink = new HttpLink({ uri: import.meta.env.VITE_GRAPHQL_URL as string });

const wsLink = new WebSocketLink({
  uri: import.meta.env.VITE_GRAPHQL_WS_URL as string,
  options: {
    reconnect: true,
    lazy: true,
    timeout: 30000,
    inactivityTimeout: 30000,
    connectionParams: async () => ({
      headers: {
        authorization: `Bearer ${await getAuthToken()}`,
      },
    }),
  },
});

const authMiddleware: RequestHandler = async (operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${await getAuthToken()}`,
    },
  });
  return forward(operation);
};

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  )),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

export default apolloClient;
