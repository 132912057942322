import { simplifiedQuoteFragment } from '#fragments/simplified_quotes';
import { gql } from '@apollo/client/core';

export const SIMPLIFIED_QUOTE_UPSERT = gql`
  ${simplifiedQuoteFragment}
  mutation UpsertSimplifiedQuote($quoteId: bigint!, $object: ai_simplified_quotes_insert_input!) {
    delete_ai_simplified_quote_items(where: { simplified_quote: { id: { _eq: $quoteId } } }) {
      affected_rows
    }
    insert_ai_simplified_quotes_one(
      object: $object,
      on_conflict: {
        constraint: simplified_quotes_pkey,
        update_columns: [
          name,
          customer,
          internal_hypothesis,
          date_of_issue,
          taxes_value,
          discounts_value,
          total_sell_price,
          tags,
        ]
      }
    ) {
      ...SimplifiedQuoteFragment
    }
  }
`;
