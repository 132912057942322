import { moduleSubcontractingLinkFragment, quoteItemSubcontractingFragment, subcontractingFragment, subcontractingVersionFragment } from '#fragments/subcontracting';
import { gql } from '@apollo/client/core';

export const SUBCONTRACTING_INSERT = gql`
${subcontractingFragment}
  mutation InsertSubcontracting($object: subcontractings_insert_input!) {
    insert_subcontractings_one(object: $object) {
      ...SubcontractingFragment
    }
  }
`;

export const SUBCONTRACTING_VERSION_UPDATE = gql`
  ${subcontractingVersionFragment}
  mutation UpdateSubcontractingVersion($object: subcontracting_versions_insert_input!, $id: Int!) {
    update_subcontracting_versions(
      where: { _and: { subcontracting_id: { _eq: $id }, is_latest: { _eq: true } } }
      _set: { is_latest: false }
    ) {
      affected_rows
    }
    insert_subcontracting_versions_one(object: $object) {
      ...SubcontractingVersionFragment
    }
  }
`;

export const SUBCONTRACTING_VERSION_BULK_UPDATE = gql`
  ${subcontractingVersionFragment}
  mutation BulkUpdateSubcontractingVersions($objects: [subcontracting_versions_insert_input!]!, $ids: [Int!]!) {
    update_subcontracting_versions(
      where: { _and: { subcontracting_id: { _in: $ids }, is_latest: { _eq: true } } }
      _set: { is_latest: false }
    ) {
      affected_rows
    }
    insert_subcontracting_versions(objects: $objects) {
      returning {
        ...SubcontractingVersionFragment
      }
    }
  }
`;

export const QUOTE_ITEM_SUBCONTRACTING_INSERT = gql`
  ${quoteItemSubcontractingFragment}
  mutation InsertQuoteItemSubcontracting($quoteSectionId: bigint!, $subcontractingId: Int!, $order: Int!) {
    insert_quote_item_subcontractings_one(object: { quote_section_id: $quoteSectionId, subcontracting_id: $subcontractingId, order: $order }) {
      ...QuoteItemSubcontractingFragment
    }
  }
`;

export const QUOTE_ITEM_SUBCONTRACTING_UPDATE = gql`
  ${quoteItemSubcontractingFragment}
  mutation UpdateQuoteItemSubcontracting($object: quote_item_subcontractings_set_input!, $id: bigint!) {
    update_quote_item_subcontractings_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteItemSubcontractingFragment
    }
  }
`;

export const QUOTE_ITEM_SUBCONTRACTING_DELETE = gql`
  mutation DeleteQuoteItemSubcontracting($id: bigint!) {
    update_quote_item_subcontractings_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
      _deleted_at
    }
  }
`;

export const MODULE_SUBCONTRACTING_LINK_UPDATE = gql`
  ${moduleSubcontractingLinkFragment}
  mutation UpdateModuleSubcontractingLink($id: bigint!, $object: module_subcontracting_link_set_input!) {
    update_module_subcontracting_link_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...ModuleSubcontractingLinkFragment
    }
  }
`;

export const MODULE_SUBCONTRACTING_LINK_DELETE = gql`
  mutation DeleteModuleSubcontractingLink($id: bigint!) {
    update_module_subcontracting_link_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
      _deleted_at
    }
  }
`;

export const MODULE_SUBCONTRACTING_LINK_INSERT = gql`
  ${moduleSubcontractingLinkFragment}
  mutation InsertModuleSubcontractingLink($object: module_subcontracting_link_insert_input!) {
    insert_module_subcontracting_link_one(object: $object) {
      ...ModuleSubcontractingLinkFragment
    }
  }
`;
