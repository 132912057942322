import { gql } from '@apollo/client/core';

export const clientSettingFragment = gql`
fragment ClientSettingFragment on client_settings {
  id
  client_id
  default_page_footer
  default_expiration_period_in_days
  _created_at
  _updated_at
  _created_by
  _updated_by
}
`;
