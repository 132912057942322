import { quoteDiscountFragment } from '#fragments/quoteDiscounts.js';
import { gql } from '@apollo/client/core';

export const QUOTE_DISCOUNT_INSERT = gql`
  ${quoteDiscountFragment}
  mutation InsertQuoteDiscount($object: quote_discounts_insert_input!) {
    insert_quote_discounts_one(object: $object) {
      ...QuoteDiscountFragment
    }
  }
`;

export const QUOTE_DISCOUNT_UPDATE = gql`
  ${quoteDiscountFragment}
  mutation UpdateQuoteDiscount($id: Int!, $object: quote_discounts_set_input!) {
    update_quote_discounts_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteDiscountFragment
    }
  }
`;

export const QUOTE_DISCOUNT_DELETE = gql`
  mutation DeleteQuoteDiscount($id: Int!) {
    update_quote_discounts_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
    }
  }
`;
