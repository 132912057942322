import { moduleRuledPriceLinkFragment } from '#fragments/ruledPrices.js';
import { gql } from '@apollo/client/core';
import { databaseObjectFragment } from './databaseObject';
import { moduleInsightsFragment } from './insight';
import { moduleSubcontractingLinkFragment } from './subcontracting';
import { tagFragment } from './tag';

export const moduleVersionFragment = gql`
  fragment ModuleVersionFragment on module_versions {
    id
    name
    version
    is_latest
    module_id
    attachments
    description
    reference
    _updated_at
    _updated_by
    _created_at
    _created_by
  }
`;

export const moduleDataFragment = gql`
fragment ModuleDataFragment on modules {
  id
  client_id
  is_standard
  from_module_id
  reference
  module_tags {
    tag {
      ...TagFragment
    }
  }
  _created_at
  _created_by
  _updated_at
  _updated_by
  _deleted_at
}
${tagFragment}
`;

export const linkedObjectModuleDataFragment = gql`
  fragment LinkedObjectModuleDataFragment on linked_object_module {
    id
    data
    value
    margin
    module_id
    database_object_id
    is_deleted_at_version
    order
    _updated_by
    _updated_at
    _created_by
    _created_at
  }
`;

export const linkedObjectModuleFragment = gql`
  ${linkedObjectModuleDataFragment}
  ${databaseObjectFragment}
  fragment LinkedObjectModuleFragment on linked_object_module {
    ...LinkedObjectModuleDataFragment
    database_object {
      ...DatabaseObjectFragment
    }
  }
`;

export const linkedModuleModuleDataFragment = gql`
  fragment LinkedModuleModuleDataFragment on linked_module_module {
    id
    value
    margin
    data
    module_id
    linked_module_id
    is_deleted_at_version
    order
    _updated_by
    _updated_at
    _created_by
    _created_at
  }
`;

export const moduleFragment = gql`
${moduleVersionFragment}
${moduleDataFragment}
${moduleInsightsFragment}
${linkedObjectModuleFragment}
${moduleRuledPriceLinkFragment}
${moduleSubcontractingLinkFragment}
fragment ModuleFragment on modules {
  ...ModuleDataFragment
  latest_module_version(where: { is_latest: { _eq: true } }) {
    ...ModuleVersionFragment
  }
  insights(order_by: { _created_at: desc }, where: { status: { _is_null: true } }){
    ...ModuleInsightsFragment
  }
  linked_object_modules(
    where: { is_deleted_at_version: { _eq: 0 }, database_object: { _deleted_at: { _is_null: true } } }
  ) {
    ...LinkedObjectModuleFragment
  }
  module_ruled_price_links(
    where: { is_deleted_at_version: { _eq: 0 }, ruled_price: { _deleted_at: { _is_null: true } } }
  ) {
    ...ModuleRuledPriceLinkFragment
  }
  module_subcontracting_link(where: { _deleted_at: { _is_null: true } }) {
    ...ModuleSubcontractingLinkFragment
  }
}
`;

export const moduleRecursiveFragment = gql`
  ${moduleFragment}
  ${linkedModuleModuleDataFragment}
  fragment ModuleRecursiveFragment on modules {
    ...ModuleFragment
    linked_object_modules(
      where: { is_deleted_at_version: { _eq: 0 }, database_object: { _deleted_at: { _is_null: true } } }
    ) {
      ...LinkedObjectModuleFragment
    }
    children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
      ...LinkedModuleModuleDataFragment
      linked_module_id
      module {
        ...ModuleFragment
        children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
          ...LinkedModuleModuleDataFragment
          module {
            ...ModuleFragment
            children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
              ...LinkedModuleModuleDataFragment
              module {
                ...ModuleFragment
                children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
                  ...LinkedModuleModuleDataFragment
                  module {
                    ...ModuleFragment
                    children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
                      ...LinkedModuleModuleDataFragment
                      module {
                        ...ModuleFragment
                        children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
                          ...LinkedModuleModuleDataFragment
                          module {
                            ...ModuleFragment
                            children_linked_module_modules(where: { is_deleted_at_version: { _eq: 0 }, module: { _deleted_at: { _is_null: true } } }) {
                              ...LinkedModuleModuleDataFragment
                              module {
                                ...ModuleFragment
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
