import { ruledPriceFragment, ruledPriceVersionFragment } from '#fragments/ruledPrices.js';
import { gql } from '@apollo/client/core';

export const RULED_PRICES_QUERY_LIST_ALL = gql`
  ${ruledPriceFragment}
  query RuledPricesQueryListAll {
    ruled_prices {
      ...RuledPriceFragment
    }
  }
`;

export const RULED_PRICES_QUERY_LIST = gql`
  ${ruledPriceFragment}
  query RuledPricesQueryList($limit: Int!, $offset: Int!, $orderBy: [ruled_prices_order_by!], $nameSearch: Stringn $where: ruled_prices_bool_exp) {
    ruled_prices(limit: $limit, offset: $offset, order_by: $orderBy, where: $where) {
      ...RuledPriceFragment
    }
  }
`;

export const RULED_PRICES_QUERY_LIST_WITH_NAME = gql`
  ${ruledPriceFragment}
  query RuledPricesQueryListWithName($limit: Int!, $offset: Int!, $orderBy: [ruled_prices_order_by!], $nameSearch: String) {
    search_ruled_prices_by_name(args: { name_search: $nameSearch }, limit: $limit, offset: $offset, order_by: $orderBy) {
      ...RuledPriceFragment
    }
  }
`;

export const RULED_PRICES_QUERY_WITH_NAME = gql`
  ${ruledPriceFragment}
  query RuledPricesQueryWithName($nameSearch: String) {
    search_ruled_prices_by_name(args: { name_search: $nameSearch }) {
      ...RuledPriceFragment
    }
  }
`;

export const RULED_PRICE_QUERY_BY_ID = gql`
  ${ruledPriceFragment}
  query RuledPriceQuery($id: bigint!) {
    ruled_prices_by_pk(id: $id) {
      ...RuledPriceFragment
    }
  }
`;

export const RULED_PRICE_UPDATE = gql`
  ${ruledPriceVersionFragment}
  mutation UpdateRuledPrice($object: ruled_price_versions_insert_input!, $id: bigint!) {
    update_ruled_price_versions(
      where: { ruled_price_id: { _eq: $id }, is_latest: { _eq: true } }
      _set: { is_latest: false }
    ) {
      affected_rows
    }
    insert_ruled_price_versions_one(object: $object) {
      ...RuledPriceVersionFragment
    }
  }
`;

export const RULED_PRICE_INSERT = gql`
  ${ruledPriceFragment}
  mutation InsertRuledPrice($object: ruled_prices_insert_input!) {
    insert_ruled_prices_one(object: $object) {
      ...RuledPriceFragment
    }
  }
`;

export const RULED_PRICE_DELETE = gql`
  mutation DeleteRuledPrice($id: bigint!) {
    update_ruled_prices_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      affected_rows
    }
  }
`;

export const ADD_TAGS_TO_RULED_PRICE = gql`
  mutation AddTagsToRuledPrice($objects: [ruled_price_tags_insert_input!]!) {
    insert_ruled_price_tags(
      objects: $objects,
    ) {
      returning {
        tag {
          id
        }
      }
    }
  }
`;

export const REMOVE_TAGS_FROM_RULED_PRICE = gql`
  mutation RemoveTagsFromRuledPrice($ruled_price_id: Int!, $tag_ids: [Int!]!) {
    delete_ruled_price_tags(
      where: {
        ruled_price_id: { _eq: $ruled_price_id },
        tag_id: { _in: $tag_ids }
      }
    ) {
      affected_rows
    }
  }
`;

export const RULED_PRICE_SEARCH_WITH_NAME = gql`
  ${ruledPriceFragment}
  query ruledPricesSearchQuery($nameSearch: String!, $searchOptions: ruled_prices_bool_exp!) {
    search_ruled_prices_by_name(args: { name_search: $nameSearch }, where: $searchOptions, limit: 15) {
      ...RuledPriceFragment
    }
  }
`;

export const RULED_PRICE_SEARCH_NO_NAME = gql`
  ${ruledPriceFragment}
  query ruledPricesSearchQuery($searchOptions: ruled_prices_bool_exp!) {
    ruled_prices(where: $searchOptions, limit: 15) {
      ...RuledPriceFragment
    }
  }
`;
