import { quoteViewFragment } from '#fragments/quoteViews';
import { gql } from '@apollo/client/core';

export const QUOTE_VIEWS_QUERY = gql`
  ${quoteViewFragment}
  query QuoteViewsQuery {
    quote_views {
      ...QuoteViewFragment
    }
  }
`;

export const QUOTE_VIEW_INSERT = gql`
  ${quoteViewFragment}
  mutation InsertQuoteView($object: quote_views_insert_input!) {
    insert_quote_views_one(object: $object) {
      ...QuoteViewFragment
    }
  }
`;

export const QUOTE_VIEW_UPDATE = gql`
  ${quoteViewFragment}
  mutation UpdateQuoteView($id: Int!, $object: quote_views_set_input!) {
    update_quote_views_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteViewFragment
    }
  }
`;

export const QUOTE_VIEW_DELETE = gql`
  ${quoteViewFragment}
  mutation DeleteQuoteView($id: Int!) {
    delete_quote_views_by_pk(id: $id) {
      ...QuoteViewFragment
    }
  }
`;
