import { simplifiedDatabaseFragment } from '#fragments/simplified_databases.js';
import { gql } from '@apollo/client/core';

export const SIMPLIFIED_DATABASE_UPSERT = gql`
  ${simplifiedDatabaseFragment}
  mutation UpsertSimplifiedDatabase($object: ai_simplified_databases_insert_input!) {
    insert_ai_simplified_databases_one(
      object: $object,
      on_conflict: {
        constraint: simplified_databases_pkey,
        update_columns: [
          name,
          description,
          attributes,
          formula,
          default_margin,
        ]
      }
    ) {
      id
    }
  }
`;
