import { gql } from '@apollo/client/core';

export const simplifiedDatabaseObjectFragment = gql`
  fragment SimplifiedDatabaseObjectFragment on ai_simplified_database_objects {
    id
    database_id
    name
    industrial_margin
    data
    _created_at
    _updated_at
  }
`;
