import { gql } from '@apollo/client/core';

export const simplifiedCustomerFragment = gql`
  fragment SimplifiedCustomerFragment on ai_simplified_customers {
    id
    client_id
    name
    tags
    _created_at
    _updated_at
  }
`;
