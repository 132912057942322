import { tagFragment } from '#fragments/tag';
import { gql } from '@apollo/client/core';

export const ruledPriceVersionFragment = gql`
  fragment RuledPriceVersionFragment on ruled_price_versions {
    id
    ruled_price_id
    version
    is_latest
    name
    config
    reference
    attachments
    _created_at
    _updated_at
    _created_by
    _updated_by
  }
`;

export const ruledPriceTagFragment = gql`
  ${tagFragment}
  fragment RuledPriceTagFragment on ruled_price_tags {
    id
    ruled_price_id
    tag_id
    tag {
      ...TagFragment
    }
    _created_at
    _updated_at
    _created_by
    _updated_by
  }
`;

export const ruledPriceDataFragment = gql`
  fragment RuledPriceDataFragment on ruled_prices {
    id
    client_id
    _deleted_at
    _created_at
    _updated_at
    _created_by
    _updated_by
    ruled_price_tags {
      ...RuledPriceTagFragment
    }
  }
`;

export const ruledPriceFragment = gql`
  ${ruledPriceDataFragment}
  ${ruledPriceVersionFragment}
  ${ruledPriceTagFragment}
  fragment RuledPriceFragment on ruled_prices {
    ...RuledPriceDataFragment
    ruled_price_versions(where: { is_latest: { _eq: true } }) {
      ...RuledPriceVersionFragment
    }
  }
`;

export const quoteItemRuledPriceDataFragment = gql`
  ${ruledPriceFragment}
  fragment QuoteItemRuledPriceDataFragment on quote_item_ruled_prices {
    id
    quote_section_id
    ruled_price_id
    name
    description
    data
    margin
    order
    _created_at
    _updated_at
    _created_by
    _updated_by
  }
`;

export const quoteItemRuledPriceFragment = gql`
  ${quoteItemRuledPriceDataFragment}
  ${ruledPriceFragment}
  fragment QuoteItemRuledPriceFragment on quote_item_ruled_prices {
    ...QuoteItemRuledPriceDataFragment
    ruled_price {
      ...RuledPriceFragment
    }
  }
`;

export const moduleRuledPriceLinkFragment = gql`
  ${ruledPriceFragment}
  fragment ModuleRuledPriceLinkFragment on module_ruled_price_links {
    id
    module_id
    ruled_price_id
    name
    data
    order
    _created_at
    _updated_at
    _created_by
    _updated_by
    ruled_price {
      ...RuledPriceFragment
    }
  }
`;
