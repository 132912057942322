import { referenceFragment, referenceVersionFragment } from '#fragments/references';
import { gql } from '@apollo/client/core';

export const REFERENCES_QUERY_LIST = gql`
${referenceFragment}
query ReferencesQueryList(
  $limit: Int
  $offset: Int
  $orderBy: [references_order_by!]
  $nameSearch: String
) {
  references(
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: { _deleted_at: { _is_null: true } }
  ) {
    ...ReferenceFragment
  }
}`;

export const REFERENCES_QUERY_LIST_WITH_NAME = gql`
${referenceFragment}
query ReferencesQueryListWithName(
  $limit: Int
  $offset: Int
  $orderBy: [references_order_by!]
  $nameSearch: String
) {
  search_references_by_name(
    args: { name_search: $nameSearch }
    limit: $limit
    offset: $offset
    order_by: $orderBy
    where: { _deleted_at: { _is_null: true } }
  ) {
    ...ReferenceFragment
  }
}`;

export const REFERENCE_QUERY = gql`
${referenceFragment}
query ReferencesQueryById($id: Int!) {
  references_by_pk(id: $id) {
    ...ReferenceFragment
  }
}`;

export const REFERENCE_VERSIONS_QUERY = gql`
${referenceVersionFragment}
query ReferenceVersionsQuery($id: Int!) {
  reference_versions(where: { reference_id: { _eq: $id } }, order_by: { _created_at: asc }) {
    ...ReferenceVersionFragment
  }
}`;

export const REFERENCES_INSERT = gql`
${referenceFragment}
mutation ReferencesInsert($object: references_insert_input!) {
  insert_references_one(object: $object) {
    ...ReferenceFragment
  }
}`;

export const REFERENCES_UPDATE = gql`
  ${referenceFragment}
  mutation UpdateReference($id: Int!, $object: references_set_input!) {
    update_references_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...ReferenceFragment
    }
  }
`;

export const REFERENCE_VERSIONS_UPDATE = gql`
${referenceFragment}
mutation UpdateReferenceVersion($id: Int!, $object: reference_versions_insert_input!) {
  update_reference_versions(
    where: { _and: { reference_id: { _eq: $id }, is_latest: { _eq: true } } }
    _set: { is_latest: false }
  ) {
    affected_rows
  }
  insert_reference_versions_one(object: $object) {
    ...ReferenceVersionFragment
  }
}
`;

export const REFERENCE_DELETE = gql`
mutation ReferenceDelete($id: Int!) {
  update_references_by_pk(
    pk_columns: { id: $id }
    _set: { _deleted_at: "now()" }
  ) {
    id
  }
}`;
