<script setup lang="ts">
const props = defineProps<{
  title?: string;
}>();

const router = useRouter();

const canGo = computed(() => {
  const history = window.history;
  return {
    back: !!history.state.back,
    forward: !!history.state.forward,
  };
});
watch(() => props.title, () => {
  document.title = `Relief - ${props.title}`;
}, { immediate: true });
</script>

<template>
  <div
    class="sticky top-0 z-100 flex flex-col gap-2 border-b border-[#D9D9D9] bg-secondary px-6 w-full max-w-full min-w-0"
    :class="$slots.tabs ? 'pt-4' : 'py-4'"
  >
    <div class="flex items-center justify-between gap-2 max-w-full min-w-0">
      <div class="flex items-center gap-3 flex-1 min-w-0">
        <div class="min-h-[34px] flex gap-2">
          <slot name="navigation">
            <div class="min-h-full flex cursor-pointer items-center" :class="canGo.back ? 'text-[#00000099]' : 'text-[#00000033]'" @click="router.back()">
              <div i="carbon-arrow-left" />
            </div>

            <div class="min-h-full flex cursor-pointer items-center" :class="canGo.forward ? 'text-[#00000099]' : 'text-[#00000033]'" @click="router.forward()">
              <div i="carbon-arrow-right" />
            </div>
          </slot>
        </div>
        <slot name="title">
          <h1 class="text-[#000000E0] font-semibold line-height-[22px]">
            {{ title || 'No title' }}
          </h1>
        </slot>
      </div>
      <div class="min-h-[34px] flex gap-2">
        <slot name="actions" />
      </div>
    </div>

    <div v-if="$slots.tabs">
      <slot name="tabs" />
    </div>
  </div>
</template>
