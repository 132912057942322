import { gql } from '@apollo/client/core';

export const aiAgentDataSourceFragment = gql`
  fragment AiAgentDataSourceFragment on ai_agent_data_sources {
    id
    ai_agent_id
    name
    description
    source_type
    data_graphql_query
    data_sql_query
    attachment
    from_ai_agent_run_id
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;

export const aiAgentFragment = gql`
  ${aiAgentDataSourceFragment}
  fragment AiAgentFragment on ai_agents {
    id
    client_id
    name
    insight_target
    mission_prompt
    trigger
    status
    data_sources {
      ...AiAgentDataSourceFragment
    }
    _created_at
    _created_by
    _updated_at
    _updated_by
    _deleted_at
  }
`;
