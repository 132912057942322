import { gql } from '@apollo/client/core';

export const notificationFragment = gql`
  fragment NotificationFragment on notifications {
    id
    notification_type
    recipient_id
    params
    read_at
    _created_at
    _updated_at
    _created_by
    _updated_by
  }
`;
