import { gql } from '@apollo/client/core';

export const aiAgentRunFragment = gql`
  fragment AiAgentRunFragment on ai_agent_runs {
    id
    ai_agent_id
    agent_type
    status
    output
    user_feedback
    _created_at
    _created_by
    _updated_at
  }
`;
