import { aiAgentFragment } from '#fragments/ai_agents';
import { gql } from '@apollo/client/core';

export const AI_AGENTS_QUERY_LIST = gql`
  ${aiAgentFragment}
  query GetAiAgents($searchName: String!) {
    ai_agents(where: { name: { _ilike: $searchName }, _deleted_at: { _is_null: true } }) {
      ...AiAgentFragment
    }
  }
`;

export const AI_AGENTS_QUERY_LIST_ALL = gql`
  ${aiAgentFragment}
  query GetAllAiAgents($target: String!) {
    ai_agents(where: { insight_target: { _eq: $target }, _deleted_at: { _is_null: true } }) {
      ...AiAgentFragment
    }
  }
`;

export const AI_AGENTS_MANUAL_QUERY_LIST = gql`
  ${aiAgentFragment}
  query GetManualAiAgents($target: String!) {
    ai_agents(where: { _and: [{ trigger: { _eq: "manual" }}, { status: { _eq: "active" }}, { insight_target: { _eq: $target }}, { _deleted_at: { _is_null: true } }] }) {
      ...AiAgentFragment
    }
  }
`;

export const AI_AGENTS_QUERY_BY_ID = gql`
  ${aiAgentFragment}
  query GetAiAgentById($id: bigint!) {
    ai_agents_by_pk(id: $id) {
      ...AiAgentFragment
    }
  }
`;

export const AI_AGENTS_INSERT = gql`
  ${aiAgentFragment}
  mutation InsertAiAgent($object: ai_agents_insert_input!) {
    insert_ai_agents_one(
      object: $object,
    ) {
      ...AiAgentFragment
    }
  }
`;

export const AI_AGENTS_UPDATE = gql`
  ${aiAgentFragment}
  mutation UpdateAiAgents($id: bigint!, $set: ai_agents_set_input) {
    update_ai_agents_by_pk(
      pk_columns: {id: $id},
      _set: $set,
    ) {
      ...AiAgentFragment
    }
  }
`;

export const AI_AGENTS_DELETE = gql`
  ${aiAgentFragment}
  mutation DeleteAiAgent($id: bigint!) {
    update_ai_agents_by_pk(
      pk_columns: {id: $id},
      _set: { _deleted_at: "now()" },
    ) {
      id
    }
  }
`;
