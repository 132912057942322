import { gql } from '@apollo/client/core';

export const jobFragment = gql`
  fragment JobFragment on jobs {
    id
    client_id
    reference
    name
    args
    status
    number_of_retries
    result
    started_at
    finished_at
    _created_at
    _updated_at
  }
`;
