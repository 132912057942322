import { simplifiedDatabaseObjectFragment } from '#fragments/simplified_database_objects';
import { gql } from '@apollo/client/core';

export const SIMPLIFIED_DATABASE_OBJECT_UPSERT = gql`
  ${simplifiedDatabaseObjectFragment}
  mutation UpsertSimplifiedDatabaseObject($object: ai_simplified_database_objects_insert_input!) {
    insert_ai_simplified_database_objects_one(
      object: $object,
      on_conflict: {
        constraint: simplified_database_objects_pkey,
        update_columns: [
          name,
          database_id,
          industrial_margin,
          data
        ]
      }
    ) {
      id
    }
  }
`;
