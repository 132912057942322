import { quoteSectionFragment } from '#fragments/quoteSection';
import { gql } from '@apollo/client/core';

export const QUOTE_SECTIONS_QUERY_LIST = gql`
  ${quoteSectionFragment}
  query QuoteSectionsQuery {
    quote_sections(order_by: { order: asc }) {
      ...QuoteSectionFragment
    }
  }
`;

export const QUOTE_SECTION_QUERY = gql`
  ${quoteSectionFragment}
  query QuoteSectionQuery($id: Int!) {
    quote_sections_by_pk(id: $id) {
      ...QuoteSectionFragment
    }
  }
`;

export const QUOTE_SECTION_UPDATE = gql`
  ${quoteSectionFragment}
  mutation UpdateQuoteSection($object: quote_sections_set_input!, $id: Int!) {
    update_quote_sections_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteSectionFragment
    }
  }
`;

export const QUOTE_SECTION_INSERT = gql`
  ${quoteSectionFragment}
  mutation InsertQuoteSection($object: quote_sections_insert_input!) {
    insert_quote_sections_one(object: $object) {
      ...QuoteSectionFragment
    }
  }
`;

export const QUOTE_SECTION_DELETE = gql`
  mutation DeleteQuoteSection($id: Int!) {
    update_quote_sections_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
    }
  }
`;
