import { businessViewFragment } from '#fragments/businessViews';
import { gql } from '@apollo/client/core';

export const BUSINESS_VIEWS_QUERY = gql`
  ${businessViewFragment}
  query BusinessViewsQuery {
    business_views {
      ...BusinessViewFragment
    }
  }
`;

export const BUSINESS_VIEW_INSERT = gql`
  ${businessViewFragment}
  mutation InsertBusinessView($object: business_views_insert_input!) {
    insert_business_views_one(object: $object) {
      ...BusinessViewFragment
    }
  }
`;

export const BUSINESS_VIEW_UPDATE = gql`
  ${businessViewFragment}
  mutation UpdateBusinessView($id: Int!, $object: business_views_set_input!) {
    update_business_views_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...BusinessViewFragment
    }
  }
`;

export const BUSINESS_VIEW_DELETE = gql`
  ${businessViewFragment}
  mutation DeleteBusinessView($id: Int!) {
    delete_business_views_by_pk(id: $id) {
      ...BusinessViewFragment
    }
  }
`;
