import { gql } from '@apollo/client/core';

export const businessViewFragment = gql`
  fragment BusinessViewFragment on business_views {
    id
    client_id
    name
    search
    tags
    _created_at
    _created_by
    _updated_at
    _updated_by
  }
`;
