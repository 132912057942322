<script setup lang="ts">
import type { PropType } from 'vue';

/**
 * @type : String => 'spin' | 'fade'
 * (Type of loader)
 *
 * @secondaryColor : Boolean
 * (If true, le loader will print with a white color)
 *
 * @size : String
 * (Size of the spin loader)
 */
const props = defineProps({
  type: {
    type: String as PropType<'spin' | 'fade'>,
    required: false,
    default: 'spin',
  },
  secondaryColor: {
    type: Boolean,
    required: false,
    default: false,
  },
  size: {
    type: String as PropType<'tiny' | 'small' | 'medium' | 'large'>,
    required: false,
    default: 'medium',
  },
  color: {
    type: String,
    required: false,
  },
});

const sizeToClassMapping = {
  tiny: 'h-5 w-5',
  small: 'h-10 w-10',
  medium: 'h-14 w-14',
  large: 'h-20 w-20',
};
</script>

<template>
  <div
    v-if="props.type === 'spin'"
    class="flex items-center justify-center"
    :class="[props.secondaryColor ? 'text-white' : 'text-primary', sizeToClassMapping[props.size]]"
  >
    <div i="carbon-circle-dash" class="animate-spin animate-duration-2000" :class="[sizeToClassMapping[props.size], props.secondaryColor ? 'text-white' : 'text-primary']" />
    <span class="sr-only">{{ $t('global.loading') }}</span>
  </div>

  <div v-else role="status" class="flex animate-pulse">
    <slot>
      <div class="h-2 w-full rounded-full bg-gray-200 dark:bg-gray-700" />
    </slot>

    <span class="sr-only">{{ $t('global.loading') }}</span>
  </div>
</template>
