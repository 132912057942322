import { simplifiedCustomerFragment } from '#fragments/simplified_customers';
import { gql } from '@apollo/client/core';

export const SIMPLIFIED_CUSTOMER_UPSERT = gql`
  ${simplifiedCustomerFragment}
  mutation UpsertSimplifiedCustomer($object: ai_simplified_customers_insert_input!) {
    insert_ai_simplified_customers_one(
      object: $object, 
      on_conflict: { 
        constraint: simplified_customers_pkey,
        update_columns: [
          name,
          tags,
        ]
      }
    ) {
      ...SimplifiedCustomerFragment
    }
  }
`;
