import { quoteFeeDataFragment, quoteFeeFragment } from '#fragments/quoteFees.js';
import { gql } from '@apollo/client/core';

export const QUOTE_FEE_INSERT = gql`
  ${quoteFeeDataFragment}
  mutation InsertQuoteFee($object: quote_fees_insert_input!) {
    insert_quote_fees_one(object: $object) {
      ...QuoteFeeDataFragment
    }
  }
`;

export const QUOTE_FEE_UPDATE = gql`
  ${quoteFeeFragment}
  mutation UpdateQuoteFee($id: Int!, $object: quote_fees_set_input!) {
    update_quote_fees_by_pk(pk_columns: { id: $id }, _set: $object) {
      ...QuoteFeeFragment
    }
  }
`;

export const QUOTE_FEE_DELETE = gql`
  mutation DeleteQuoteFee($id: Int!) {
    update_quote_fees_by_pk(pk_columns: { id: $id }, _set: { _deleted_at: "now()" }) {
      id
    }
  }
`;
